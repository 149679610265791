import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS } from "@contentful/rich-text-types"

const PageHeader = ({ title, body, sousTitre, sousBody, headingImage, cta }) => (
  <div id="page-header">
    <div className="title-container">
      <h1>{title}</h1>
    </div>
    {body && (
      <div className="body-container">
        <div className="body-content">
          <p dangerouslySetInnerHTML={{ __html: body }}/>
        </div>
      </div>
    )}
    {sousTitre && (
      <div id="block">
        <ColumnText titre={sousTitre} body={sousBody} cta={cta} />
        <ColumnImage image={headingImage} />
      </div>
    )}
  </div>
)

const options = {
  renderNode: {
    [BLOCKS.HEADING_5]: (node, children) => <h5>{children}</h5>,
  },
}

const ColumnText = ({ titre, body, cta }) => (
  <div id="column-text">
    <h3 dangerouslySetInnerHTML={{ __html: titre }} />
    <p className="content">{renderRichText(body, options)}</p>
    {
      cta && (
        <p className="fake-cta">{cta}</p>
      )
    }
  </div>
)

const ColumnImage = ({ image }) => (
  <div id="column-img">
    <GatsbyImage image={getImage(image)} imgStyle={{ objectFit: "contain" }} alt={image.description} className="img"/>
  </div>
)

export default PageHeader

PageHeader.propTypes = {
  data: PropTypes.object.isRequired
}
